
@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/variables";

.entry {
    margin-bottom: remCal(100);
    &-form {
        form {
          .form-group {
            margin-bottom: emCal(14, 20);

            label {
              padding-left: 0;
              padding-right: 0;
            }
            input:not([type="radio"]) {
              @include input($height: emCal(14, 45));

              &.form-control-file {
                padding: 0 30px;
              }

            }

            textarea {
              @include input;
              resize: none;
            }


            div.input-error {
              color: red !important;
            }
          }

         
        }
      }
    }