@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/variables";
 

.top-nav {
  background-color: setShadeColor(dark);
  padding: emCal(16, 12) 0;

  @include mq(xl) {
    font-size: 15px;
  }
  @include mq(lg) {
    display: none;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__promo {
      @include typo($color: #fff, $fsz: emCal(16, 13));
    }
    &__selectors {
      display: flex;
      align-items: center;
      select {
        @include typo($fsz: emCal(16, 13));
        margin-right: emCal(13, 30);
      }
      .top-nav__auth {
        @include link(
          $color: #fff,
          $hvr-color: setShadeColor(light),
          $fsz: emCal(16, 13)
        );

        
        &::after {
          content: "|";
          margin: 0 emCal(13, 10);
          color: #fff;
       }

       &:last-child:after {
        content: " ";
        margin: 0 emCal(13, 10);
        color: #fff;
       }
       
        
      }
    }
  }
  &.-style-3 {
    background-color: setShadeColor(white);
    .top-nav__wrapper {
      ul {
        &.-white.social-icons{
        li {
            display: inline-block;
            a {
              @include link(
                $fsz: emCal(16, 13),
                $color: #000,
                $hvr-color: setShadeColor(light)
              );
            }
         }
        }
      }

      &__selectors{
        color: #000;
        a {
          @include link(
            $fsz: emCal(16, 13),
            $color: #000,
            $hvr-color: setShadeColor(light)
          );
        }
        &::after {
          content: "|";
          margin: 0 emCal(13, 10);
          color: #000;
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
      }
      &__promo {
        @include mq(lg) {
          display: none;
        }
      }
      &__quick-links {
        ul {
          li {
            display: inline-block;
            a {
              @include link(
                $fsz: emCal(16, 13),
                $color: #000,
                $hvr-color: setShadeColor(light)
              );
            }
            &::after {
              content: "|";
              margin: 0 emCal(13, 10);
              color: #000;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.-style-2 {
    .top-nav__wrapper {
      &__promo {
        @include mq(lg) {
          display: none;
        }
      }
      &__quick-links {
        ul {
          li {
            display: inline-block;
            a {
              @include link(
                $fsz: emCal(16, 13),
                $color: #fff,
                $hvr-color: setShadeColor(light)
              );
            }
            &::after {
              content: "|";
              margin: 0 emCal(13, 10);
              color: #fff;
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
