
.all-columns {
	display: flex;
	position: relative;
}
.column-text {
	max-width: 60em;
	font-size: 15px;
	margin: 0 auto;
	// border-left: 1px solid rgb(214, 217, 220);
	width: 100%;
	.header {
		padding: 1.5rem 0 0 1.5rem;
		margin-bottom: 1rem;
		>* {
			margin-top: 0;
		}
	}
	.content {
		border-top: 1px solid rgb(214, 217, 220);
		.element {
			border-bottom: 1px solid rgb(239, 240, 241);
			padding-top: 0.75rem;
			padding-bottom: 0.75rem;
			&:last-child {
				border-bottom: none;
			}
		}
		.element.first {
			border-bottom: none;
		}
	}
}
.column-text.none {
	.content {
		border-top: none;
		padding-left: 1.5rem;
		.element {
			border: none;
		}
	}
}
.tabbed {
	.element {
		border-bottom: 1px solid rgb(239, 240, 241);
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		&:last-child {
			border-bottom: none;
		}
	}
	.element.first {
		border-bottom: none;
	}
	margin: 1.5rem 0 0 1.5rem;
	padding-bottom: 0.75em;
}
.column-text.closed {
	.content {
		.element {
			padding-left: 0.5rem;
		}
	}
}
.column-text.open {
	.content {
		padding-left: 1.5rem;
		span {
			@include typo(
				$fsz: emCal(16, 15) !important,
				$lh: emCal(15, 30) !important,
				// $fw: 400 !important,
			  
				$color: setShadeColor(medium) !important
			  );
			  font-family: "Spartan", sans-serif !important;
		}
	}
}
.column-text.grid {
	.content {
		padding-left: 1.5rem;
	}
	max-width: 71.5em;
}
.element {
	h2 {
		margin-top: 0;
	}
}
.column-wide {
	max-width: 100%;
	position: relative;
}
.column-right {
	padding: 2em 0.5em 0.5em 0.5em;
	flex: none;
	position: relative;
}
.column-right-button {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
}
.column-close-button {
	position: absolute;
	top: 0.5em;
	right: 0.5em;
	line-height: 1em;
	border: none;
	background-color: transparent;
	padding: 0.5em;
	&:hover {
		background-color: rgba(159, 166, 173, 0.5);
		border: 1px solid rgb(159, 166, 173);
	}
}
.tab-head {
	font-size: 12px;
	line-height: 1;
	height: 41px;
	border-bottom: 1px solid rgb(228, 230, 232);
	clear: both;
	.slug {
		float: right;
		margin-top: 3px;
		h1 {
			font-size: 17px;
			font-weight: bold;
			margin: 0 8px;
		}
		>* {
			display: inline-block;
			vertical-align: middle;
			float: none;
			padding: 0;
		}
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
.tab-head.profile {
	margin: 1.5rem 0 0 1.5rem;
}
.tabs {
	float: left;
	width: 100%;
	a {
		display: block;
		float: left;
		padding: 13px 7px 14px;
		border: 1px solid rgb(228, 230, 232);
		border: 1px solid transparent;
		color: rgb(36, 39, 41);
		position: relative;
		box-sizing: border-box;
		margin-right: 8px;
		&:before {
			content: "";
			position: absolute;
			top: -1px;
			left: -1px;
			right: -1px;
			height: 2px;
			background-color: transparent;
		}
		&:hover {
			background-color: rgba(132, 141, 149, 0.05);
		}
	}
	&::after {
		content: "";
		display: table;
		clear: both;
	}
	a.selected {
		border-color: rgb(228, 230, 232);
		border-bottom-color: white;
		&:before {
			background-color: #2c5777;
		}
		&:hover {
			background-color: white;
		}
	}
}
.subtabs {
	h2 {
		display: inline;
	}
	.tabs {
		>div {
			float: right;
		}
		a {
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

ul.image-layers {
	list-style-type: none;
	padding-left: 1.5em;
	padding-left: 0;
	margin: 0;
	ul {
		list-style-type: none;
		padding-left: 1.5em;
	}
	>li {
		margin-top: 0.5em;
		&:first-child {
			margin-top: 0;
		}
	}
	li.parent {
		margin-top: 0.5em;
		>ul {
			>li {
				&:first-child {
					margin-top: 0.5em;
				}
			}
		}
		>label {
			font-weight: bold;
		}
	}
	input {
		margin-right: 0.5em;
	}
}
div.image-images {
	>img {
		max-width: 100%;
	}
}
div.features {
	>span {
		&::before {
			content: " ";
		}
	}
}
div.all-users {
	flex-wrap: wrap;
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	padding-top: 8px;
	>.user-info {
		flex: 1 1 25%;
		width: auto;
		overflow: hidden;
	}
}
div.all-tags {
	flex-wrap: wrap;
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	>.tag-info {
		flex: 1 1 25%;
		width: auto;
		overflow: hidden;
	}
}
.user-info {
	padding: 6px 6px 6px 7px;
}
div.tags {
	flex-wrap: wrap;
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	margin-bottom: 2em;
	>.topic {
		flex: 1 1 25%;
		width: auto;
	}
}
.tag-info {
	padding: 8px 4px 12px;
	border-bottom: 1px solid rgb(228, 230, 232);
	>.exerpt {
		font-size: 12px;
		line-height: 14px;
		height: 39px;
		padding: 4px 8px 5px 0;
		box-sizing: content-box;
		margin: 0;
		overflow: hidden;
	}
	.edit-link {
		display: none;
		float: right;
		font-size: 11px;
	}
	&:hover {
		.edit-link {
			display: block;
		}
	}
	>.multiplier {
		font-size: 13px;
		color: rgb(132, 141, 149);
	}
}
.user-profile.profile {
	display: flex;
	.column {
		flex-grow: 1;
	}
	>.column {
		>h1 {
			margin: 0;
		}
	}
	>.gravatar {
		margin-right: 2em;
	}
	.location {
		vertical-align: middle;
		color: rgb(59, 64, 69);
		margin-top: 0.25em;
		* {
			vertical-align: middle;
			color: rgb(59, 64, 69);
		}
		svg {
			fill: rgb(145, 153, 161);
		}
	}
}
.user-profile.settings {
	.public {
		display: flex;
		.column {
			flex-grow: 1;
		}
	}
	.gravatar {
		margin-right: 2em;
	}
}
.minigrid {
    display: flex;
    justify-content: space-between;
	>* {
		display: flex;
		padding-left: 12px;
	}
 
	> &:first-child {
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;
			margin-right: 12px;
			padding-left: 0;
	}
 
	.count {
		font-size: 17px;
	}
	h1 {
		margin-top: 0;
	}
	.sort {
		a {
			font-size: 13px;
			padding: 0.8em;
			border: 1px solid rgb(118, 128, 138);
			margin-right: -1px;
			margin-bottom: -1px;
			&:last-child {
				margin-right: 0;
				border-top-right-radius: 3px;
				border-bottom-right-radius: 3px;
			}
			&:first-child {
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
			}
		}
		.selected {
			background-color: rgba(174, 192, 209, 0.25);
		}
	}
	.page {
		a {
			font-size: 12px;
			padding: 4px 8px;
			border: 1px solid rgb(228, 230, 232);
			margin-bottom: -1px;
			border-radius: 3px;
			margin-right: 4px;
			color: rgb(132, 141, 149);
		}
		span {
			font-size: 12px;
			padding: 4px 8px;
			border: 1px solid rgb(228, 230, 232);
			margin-bottom: -1px;
			border-radius: 3px;
			margin-right: 4px;
			color: rgb(132, 141, 149);
		}
		.dots {
			border-color: transparent;
		}
	}
	>.link {
		display: block;
	}
	.linkbutton {
		display: inline-flex;
	}
}
.footer {
	margin: 38px 0 20px;
	padding-left: 16px;
}
.minigrid.footer {
	align-items: center;
}
.minigrid.subtitle {
    align-items: center;
    justify-content: space-between;
}
.minigrid.links {
	ul {
		list-style: none;
		margin: 0 0 1em;
		>li {
			margin: 0.5em;
		}
	}
}
.tag-wiki {
	.summary {
		padding: 10px;
		border: 2px solid #e4e6e8;
		margin-bottom: 8px;
	}
	.summary.none {
		background-color: #fff5ee;
		border-color: #ff8c00;
		margin: 10px 0;
	}
	.link {
		.linkbutton {
			display: inline-block;
		}
	}
}
div.search {
	position: relative;
	>input {
		padding: 0.6em 0.7em 0.6em 32px;
	}
	>svg {
		position: absolute;
		height: 18px;
		width: 18px;
		margin-top: -9px;
		left: 0.7em;
		top: 50%;
		fill: rgb(187, 192, 196);
	}
}


