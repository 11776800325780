$colors: (
  red: #ff8000,
  darkRed: #c55100,
  star: #ecba0b,
  blue: #1a7be9,
);

$shades: (
  dark: #111,
  medium: #888,
  light: #e1e1e1,
);

$breakpoint: (
  sm: " 576px",
  md: "768px",
  lg: "992px",
  xl: "1170px",
  xxl: "1400px",
  xxxl: "1770px",
);

$rem-baseline: 16;
$transition: 0.2s ease-in-out;
$prefixes: (webkit, moz, ms, o);
$cambay: "Cambay", sans-serif;
