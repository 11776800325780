@mixin imgCenter($height: 100%, $width: 100%, $type: cover) {
  height: $height;
  width: $width;
  object-fit: $type;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    @include imgCenter($height: emCal(16, 200), $width: emCal(16, 200));
  }
}
