/* discussion-summary */
.user-info {
	&:hover {
		background-color: #f4f8fb;
	}
	>a {
		float: left;
	}
	> .p-info{
		display: flex;
		flex-direction: row;
		justify-items: center;
		align-items: center;

	 

		 
	}
	>.details {
		float: left;
		margin-left: 10px;
		font-size: 14px;
		color: rgb(76, 141, 189);
		text-transform: capitalize;
		>.user-location {
			display: block;
			font-size: 12px;
			color: rgb(106, 115, 124);
		}
	}
	.when {
		font-size: 12px;
		color: rgb(145, 153, 161);
		margin: 1px 0 2px;
	}
	&::after {
		content: "";
		clear: both;
		display: table;
	}
}
.user-profile {
	.user-location {
		display: block;
		font-size: 12px;
		color: rgb(106, 115, 124);
	}
}
.discussion-summary {
	h3 {
	    font-size: emCal(16, 16);
		margin-top: 0;
		margin-bottom: 0.5em;
		>a {
			font-size: emCal(16, 18);
			font-weight: normal;
			color: rgb(44, 87, 119);
		}
	}
	.excerpt {
		padding-bottom: 5px;
		color: setShadeColor(medium) !important;
		span{
	     	@include typo(
		  		$fsz: emCal(16, 15) !important,
				$lh: emCal(15, 30) !important,
				$color: setShadeColor(medium) !important
			  );
			  font-family: "Spartan", sans-serif !important;
		}
	}
	.user-info {
		padding: 5px 6px 7px 7px;
		float: right;
		width: 200px;
		> .p-info{
			display: flex;
			flex-direction: row;
		}
		> .details {
		 
				text-transform: capitalize;
			 
		}
	}
	overflow: hidden;
	display: flex;
	.stats {
		width: 58px;
		flex-shrink: 0;
		margin: 0 16px 0 8px;
		strong {
			display: block;
			font-size: 20px;
			font-weight: normal;
		}
		.answers {
			padding: 7px 0 5px;
			border: 1px solid rgba(69, 161, 99, 0.5);
			font-size: 11px;
			color: rgb(69, 161, 99);
			text-align: center;
			border-radius: 3px;
		}
	}
	.summary {
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: auto;
	}
}
.message {
	.user-info {
		padding: 5px 6px 7px 7px;
		float: right;
		width: 200px;
	}
	.topic {
		float: left;
		>* {
			&:not(:first-child) {
				&::before {
					content: " ";
				}
			}
		}
	}
	overflow: hidden;
}
.summary {
	.topic {
		float: left;
		>* {
			&:not(:first-child) {
				&::before {
					content: " ";
				}
			}
		}
	}
}
.tags {
	.topic {
		padding: 5px 6px 7px 7px;
	}
}
.tag {
	padding: 5px 6px 7px 7px;
	display: inline-block;
	font-size: 12px;
	padding: 0.4em 0.5em;
	border: 1px solid rgb(225, 236, 244);
	background-color: rgb(225, 236, 244);
	margin: 2px 2px 2px 0;
	border-radius: 3px;
}
