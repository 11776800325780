.wmd-preview {
	padding: 0;
	clear: both;
	width: 100%;
	border-top: 1px solid rgb(214, 217, 220);
	border-bottom: 1px solid rgb(214, 217, 220);
	background-color: white;
}
form.editor {
	width: 100%;
	padding: 0;
    border: none;
    h2 {
        padding: 20px 0px;
    }


    button {
        margin: 20px 0px;
        text-transform: capitalize;
    }
}
.wmd-input {
	background-color: white;
}
.element {
	.wmd-preview {
		border-top: none;
		border-bottom: none;
	}
}
.editor.settings {
	>h1 {
		font-size: 1.5em;
	}
	>h2 {
		margin-top: 1.5em;
		padding-top: 0.5em;
		border-top: 1px solid rgb(234, 234, 234);
		font-size: 18px;
	}
	input[type="text"] {
		display: block;
		margin: 5px 0 6px;
		width: 100%;
	}
	label {
		display: block;
		margin-top: 18px;
	}
	.column {
		>label {
			&:first-child {
				margin-top: 0;
			}
		}
	}
	div.submit {
		text-align: center;
	}
}
.editor {
	div.submit {
		margin-top: 24px;
	}
}
.wmd-input.invalid {
	border-top-color: darkgray !important;
}
textarea {
	width: 100%;
}
.editor.tag-wiki {
	.hint {
		margin: 0;
		font-size: 13px;
		color: #9199a1;
	}
	.hint.next {
		margin-top: 5px;
	}
}


    .rdw-editor-wrapper{
        border: 1px solid #a9a9a9;
    
        > .rdw-editor-main{
           padding: 15px;
        }
    }
    
 