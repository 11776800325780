@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../components/button";

.slider {
  position: relative;
  overflow: hidden;
  background-color: #f4f4f4;
  @include mq(md) {
    font-size: 14px;
  }
  &-animation-wrapper {
    overflow: hidden;
  }
  &-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    @include imgCenter;
  }
 
  &-content {
   
    &__subtitle {
     
      margin-bottom: emCal(16, 20);
      @include typo(
        $color: #fff,
        $fsz: emCal(16, 17),
        $lh: emCal(17, 26),
        $up: true,
        $fw: 400
      );
      letter-spacing: emCal(17, 5);
      font-family: $cambay;
    }
    &__title {
     
      @include typo(
        $color: #fff,
        $fsz: emCal(16, 60),
        $lh: emCal(60, 70),
        $fw: 600
      );
      margin-bottom: emCal(60, 25);
    }
    &__description {
     
      @include typo(
        $color: #fff,
        $fsz: emCal(16, 17),
        $lh: emCal(17, 30),
        $fw: 500
      );
    }

    
  }
  .slider__carousel {
    &__item {
      display: flex !important;
      align-items: center;
      height: emCal(16, 600);
      position: relative;
      z-index: 2;
      outline: 0;
      .slider-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        img {
          @include imgCenter;
        }
      }
    }
    .slider-dots {
      position: absolute;
      bottom: emCal(16, 30);
      left: 0;
      right: 0;
      text-align: center;
      li {
        display: inline-block;
        margin: 0 emCal(16, 5);
        @include spaceTrim;
        &.slick-active {
          .slider-dot {
            background-color: rgba(#000, $alpha: 0.5);
          }
        }
        .slider-dot {
          height: emCal(16, 10);
          width: emCal(16, 10);
          border-radius: 999px;
          background-color: rgba(#000, $alpha: 0.2);
          cursor: pointer;
        }
      }
    }
  }

  &.-style-2 {
    margin-bottom: remCal(30);
    .slider__carousel {
      &__item {
        &.slider-1 {
          .slider-content {
            max-width: 400px;
            &__title {
              color: #fff;
              font-weight: 700;
              span {
                color: setColor(#ff8000);
              }
            }
            &__description {
              color: #fff;
              margin-bottom: emCal(17, 35);
            }
            .btn {
              color: setColor(#ff8000);
            }
          }
        }
        &.slider-2 {
          .slider-content {
            max-width: emCal(16, 550);
            &__subtitle {
              color: setColor(#c55100);
            }
            &__title {
              color: setShadeColor(dark);
              font-weight: 700;
            }
            &__description {
              color: setShadeColor(dark);
              font-weight: 500;
              margin-bottom: emCal(17, 30);
            }
          }
        }
        &.slider-3 {
          text-align: center;
          .slider-content {
            &__subtitle {
              color: setColor(darkRed);
            }
            .btn {
              color: setColor(red);
            }
          }
        }
      }
    }
  }
  &.-style-1 {
    margin-bottom: remCal(100);
    .slider-dots {
      li {
        &.slick-active {
          .slider-dot {
            background-color: setColor(red);
          }
        }
        .slider-dot {
          background-color: #fff;
        }
      }
    }
    .slider__carousel {
      &__item {
        .slider-content {
          max-width: 100%;
            &:hover{
                padding: 4%;
                background-color: rgba( #000, $alpha: 0.4);
                width: fit-content;
                border-radius: 0.2em;
                transition: 0.2s ease-in-out;
            }
         
          &__title {
            color: #fff;
          }
          &__subtitle {
            color: #fff;
          }
          .btn {
            color: #fff;
          }
        }
      }
    }
  }
  &.-style-3 {
    margin-bottom: remCal(100);
    .slider__carousel {
      &__item {
        height: emCal(16, 700);
        .slider-content {
          margin: 0 auto;
          background-color: #fff;
          max-width: 690px;
          text-align: center;
          padding: emCal(16, 50);
          border: 1px dashed rgba(setColor(red), $alpha: 0.5);
          outline: emCal(16, 10) solid #fff;
          min-height: emCal(16, 345);
          &__title {
            font-weight: 600;
            color: setShadeColor(dark);
            @include mq(md) {
              font-size: emCal(14, 40);
            }
          }
          &__description {
            color: setShadeColor(dark);
            max-width: 450px;
            margin: 0 auto;
            margin-bottom: emCal(16, 35);
          }
        }
      }
      .slick-arrow {
        position: absolute;
        top: 50%;
        @extend .btn;
        padding: 0;
        font-size: emCal(16, 30);
        height: emCal(30, 50);
        width: emCal(30, 50);
        line-height: emCal(30, 50);
        background-color: #fff;
        z-index: 2;
        border: 1px solid setShadeColor(light);
        color: setShadeColor(medium);

        
        &.slick-prev {
          left: emCal(30, 50);
        }
        &.slick-next {
          right: emCal(30, 50);
        }
        @include mq(md) {
          &.slick-prev {
            left: emCal(30, 15);
          }
          &.slick-next {
            right: emCal(30, 15);
          }
        }
      }
    }
  }
  &.-style-4 {
    margin-bottom: remCal(100);
    .slider__carousel {
      &__item {
        height: emCal(16, 650);

        .slider-content {
          max-width: 100%;
          &__subtitle {
            color: setShadeColor(dark);
            font-weight: 600;
          }
          &__title {
            color: setColor(red);
            font-weight: 700;
            span {
              color: setShadeColor(dark);
            }
            @include mq(md) {
              font-size: emCal(14, 40);
            }
            br {
              display: none;
            }
          }
        }
      }
      .slider-dots {
        position: absolute;
        bottom: emCal(16, 38);
        left: 0;
        right: 0;
        z-index: 5;
        text-align: left;
      }
    }
  }
  &.-style-5 {
    @extend .-style-1;
    padding: emCal(16, 280) 0;
    .slider-background {
      animation: scaleIn 5s forwards;
    }
    .slider-content {
      max-width: emCal(16, 600);
      position: relative;
      z-index: 1;
      &__subtitle {
        color: setColor(red);
      }
      &__title {
        color: setShadeColor(dark);
      }
      &__description {
        font-size: emCal(16, 15);
        margin-bottom: emCal(15, 45);
        color: setShadeColor(dark);
      }
    }
  }
  &.-style-7 {
    .slider__carousel {
      &__item {
        .slider-content {
          &__title {
            font-family: "Braga";
            font-weight: 400;
            padding-left: emCal(60, 15);
            padding-right: emCal(60, 15);
            line-height: emCal(60, 110);
          }
        }
        &.slider-2 {
          text-align: center;
        }
      }
    }
  }
}

@keyframes scaleIn {
  from {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}
