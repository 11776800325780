@function remCal($size) {
  @if ($size == "auto" or $size == "100%") {
    @return $size;
  }
  @return ($size/$rem-baseline) * 1rem;
}

@function emCal($em, $size) {
  @return ($size/$em) * 1em;
}

@function setColor($color) {
  @if map-has-key($colors, $color) {
    @return map-get($colors, $color);
  } @else {
    @return $color;
  }
}

@function setShadeColor($color) {
  @if map-has-key($shades, $color) {
    @return map-get($shades, $color);
  } @else {
    @return $color;
  }
}
