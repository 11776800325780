@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/variables";
@import "../components/button";

.paginator {
  list-style: none;
  li {
    display: inline-block;
    margin: 0 emCal(16, 5);
    @include spaceTrim;
    &.active {
      button {
        @extend .btn, .-dark;
        height: emCal(14, 50);
        width: emCal(14, 50);
        padding: 0;
      }
    }
    button {
      @extend .btn, .-white, .-round;
      height: emCal(14, 50);
      width: emCal(14, 50);
      padding: 0;
    }
  }
}


.page-item {
  .page-link{
    color: setShadeColor(dark);
   }
   &.active{
     .page-link{
      background-color: #ff8000;
      border-color: #ff8000;
     }
   }
}